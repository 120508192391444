<template>
  <button
    class="group relative outline-none transition focus-visible:ring-2 active:scale-[0.98]"
    type="button"
    @click="onClick"
  >
    <transition
      enter-active-class="transition ease-out"
      enter-from-class="-translate-x-3 opacity-0"
      leave-active-class="transition ease-out"
      leave-to-class="translate-x-3 opacity-0"
    >
      <p
        v-show="showNotification"
        class="absolute left-[calc(100%+4px)] z-50 flex items-center justify-center gap-1 whitespace-nowrap rounded-sm bg-success-950 text-success-50 p-1 font-sans text-xs backdrop-blur"
      >
        <app-icon icon="ph:check" :size="16" />
        {{ $t("app.copy_text_success_message") }}
      </p>
    </transition>

    <slot>
      <app-icon
        class="text-subtle opacity-50 transition-opacity hover:opacity-100"
        icon="ph:copy-simple"
        :size="iconSize"
      />
    </slot>

    <app-icon
      v-if="copyIconOnHover"
      class="absolute overflow-hidden w-0 h-0 -right-1 top-1/2 -translate-y-1/2 translate-x-full group-hover:w-auto transition group-hover:h-auto opacity-0 group-hover:opacity-70"
      icon="ph:copy-simple"
      :size="20"
    />
  </button>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  iconSize?: number;
  text: string;
  copyIconOnHover?: boolean;
}>();

const showNotification = ref(false);

const onClick = async () => {
  await navigator.clipboard.writeText(properties.text);
  showNotification.value = true;
  await timer(700);
  showNotification.value = false;
};
</script>
